/* .at-active{
    background: #1f497d;
    color: blueviolet;
} */

.page-link{
    color:#1f497d;
}

.page-item.at-active .page-link{
    z-index: 3;
    color: #fff;
    background-color: #1f497d;
    border-color: #1f497d;
}